import React from "react"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import EventList from "../../../components/event-list"
import Heading from "../../../components/heading"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import Box from "../../../components/box"
import Button from "../../../components/button"

const IndexPage = () => {
  return (
    <Layout backdrop="hegel-hoelderlin">
      <Seo
        title="Idealismusschmiede in der Philosophen-WG"
        description="Hölderlin und Hegel verbrachten 5 gemeinsame Studienjahre in Tübingen und teilten sich zuweilen sogar ein Zimmer. Die Ausstellung geht den Anfängen der beiden Geistesgrößen nach, zeigt, wie sie sich gegenseitig in ihrem Denken beeinflusst haben und wie sie heute gelesen und betrachtet werden."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Ausstellungen",
              link: "/ausstellungen",
            },
            {
              title: "Idealismusschmiede in der Philosophen-WG",
              link: "/ausstellungen/sonderausstellungen/hegel-hoelderlin",
            },
          ]}
        />
        <Stack space={[12, 24]}>
          <Stack>
            <PageTitle>Idealismusschmiede in der Philosophen-WG</PageTitle>
            <Heading level={3}>
              Hegel, Hölderlin und ihre Tübinger Studienjahre
            </Heading>
            <Heading level={4} color="primary">
              12. Juni bis 31. Oktober
            </Heading>
            <Paragraph>
              Friedrich Hölderlin und Georg Wilhelm Friedrich Hegel, beide 1770
              geboren – der eine im März, der andere im August –, verbrachten
              fünf gemeinsame Studienjahre am Evangelischen Stift in Tübingen.
              Beide sollten sie Theologen werden. Aus dem einen wurde ein
              Dichter, aus dem anderen ein Philosoph. Die Sprache, ihr Einfluss
              auf unser Denken und ihr Vermögen, die Welt zu erfassen,
              beschäftigte sie beide in ihren Werken und ihrem gegenseitigen
              Gedankenaustausch.
            </Paragraph>
            <Paragraph>
              In Kooperation mit dem Philosophischen Seminar der Eberhard Karls
              Universität Tübingen geht die Ausstellung den Tübinger Anfängen
              der beiden Geistesgrößen nach, zeigt, wie sie sich gegenseitig in
              ihrem Denken beeinflusst haben und wie sie heute gelesen und
              betrachtet werden. Zeichnungen und Prosaminiaturen der Künstlerin
              Veronika Reichl laden dabei zum Weiterdenken und Mitmachen ein.
            </Paragraph>
          </Stack>

          <Box bg="muted" p={[6, 12]}>
            <Stack>
              <Stack space={6}>
                <Heading level={3}>Digitale Sonderausstellung</Heading>
                <Paragraph>
                  Unsere digitalen Angebote zu den Sonderausstellungen bereiten
                  auf den Ausstellungsbesuch im Hölderlinturm vor oder bieten
                  die Möglichkeit, das Gesehene noch einmal zu vertiefen.
                </Paragraph>
              </Stack>
              <Button to="/sonderausstellungen/hegel-hoelderlin">
                Zur Digitalen Sonderausstellung
              </Button>
            </Stack>
          </Box>

          <Stack>
            <Heading level={2}>Wie sich Denken versprachlicht</Heading>
            <Paragraph>
              Begleitend zur Sonderausstellung wird in Lesungen, Gesprächen,
              Performances und Workshops über den Umgang mit philosophischen
              Themen und Fragestellungen nachgedacht. Verschiedene Gäste aus den
              Bereichen Philosophie, Literatur, Kunst und Musik präsentieren
              ihre individuellen Annäherungen an Hegel, Hölderlin und die
              Philosophie.
            </Paragraph>
            <Paragraph>
              Mit dabei sind Erich Witschke, der einen Roman über die
              Freundschaft zwischen Hegel, Hölderlin und Schelling geschrieben
              hat (Klöpfer, Narr 2019), der Philosoph Klaus Vieweg, der in
              seiner Hegel-Biografie einen „Philosophen der Freiheit“
              porträtiert hat (C.H. Beck 2019), der Philosoph Eike Brock und der
              Rapper Spax, die über die Philosophie des HipHop diskutieren und
              die Künstlerin Veronika Reichl, sich in Animationen,
              Performance-Lectures und literarischen Porträts mit der Erfahrung
              des Theorie-Lesens auseinandersetzt. In vier aufeinander
              aufbauenden Workshops wird außerdem der Versuch unternommen, kurze
              philosophische Texte von Hegel und Hölderlin in leichte Sprache zu
              übersetzen.
            </Paragraph>
            <EventList
              density="compact"
              showPastEvents={true}
              category="hegel-hoelderlin"
            />
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default IndexPage
